<template>
  <div class="w-100">
    <span v-for="(section, index) in sections" :key="section" :ref="section" :id="section" class="w-100">
      <component :is="getComponent(section)" :isBackgroundPrimary="index % 2 === 0" @scrollToSection="scrollToSection"
        v-bind="getProps(section)" />
    </span>
  </div>
</template>

<script>
import HeroSection from './components/HeroSection.vue';
import FunctionalitiesSection from './components/FunctionalitiesSection.vue';
import FormSection from './components/FormSection.vue';

export default {
  components: {
    HeroSection,
    FunctionalitiesSection,
    FormSection,
  },
  data() {
    return {
      sections: ['hero', 'functionalities', 'form'],
      currentSectionIndex: 0,
      isScrolling: false,
    };
  },
  methods: {
    getComponent(section) {
      const components = {
        hero: 'HeroSection',
        functionalities: 'FunctionalitiesSection',
        form: 'FormSection',
      };
      return components[section];
    },
    getProps(section) {
      // Provide specific props based on the section
      if (section === 'hero') {
        return {
          title: 'AquaDentist',
          subtitle: 'Pour une organisation optimisée de votre cabinet dentaire.',
          backgroundImage: require('@/assets/images/banner/aquaMedico-banner.jpg'),
          // exploreButtonText: 'Explorer',
          // mainButtonText: 'AquaMedico',
          // mainButtonRoute: { name: 'aqua-main' },
        };
      } else if (section === 'functionalities') {
        return {
          title: 'FONCTIONNALITÉS',
          subtitle: 'Facile à utiliser',
          images: [
            require('@/assets/images/banner/banner-19.jpg'),
            require('@/assets/images/banner/banner-11.jpg'),
            require('@/assets/images/banner/banner-12.jpg'),
          ],
        };
      } else if (section === 'form') {
        return {
          specialty: 'dentist',
        };
      }
      return {};
    },
    scrollToSection(sectionId) {
      const element = this.$refs[sectionId][0];
      if (element) {
        const yOffset = 0; // Adjust the offset if you have a fixed header
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    },
    handleScroll(event) {
      if (this.isScrolling) return; // Prevent multiple triggers
      this.isScrolling = true;
      setTimeout(() => {
        this.isScrolling = false;
      }, 500); // Adjust the delay as needed

      const currentSection = this.$refs[this.sections[this.currentSectionIndex]][0];
      const sectionBottom = currentSection.getBoundingClientRect().bottom;
      const sectionTop = currentSection.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight;

      if (event.deltaY > 0 && sectionBottom <= viewportHeight + 50) {
        // Scroll down
        this.scrollToNextSection();
      } else if (event.deltaY < 0 && sectionTop >= -50) {
        // Scroll up
        this.scrollToPreviousSection();
      }
    },
    scrollToNextSection() {
      if (this.currentSectionIndex < this.sections.length - 1) {
        this.currentSectionIndex++;
        this.scrollToSection(this.sections[this.currentSectionIndex]);
      }
    },
    scrollToPreviousSection() {
      if (this.currentSectionIndex > 0) {
        this.currentSectionIndex--;
        this.scrollToSection(this.sections[this.currentSectionIndex]);
      }
    },
  },
  mounted() {
    this.sections.forEach(section => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.currentSectionIndex = this.sections.indexOf(entry.target.id);
          }
        });
      }, {
        threshold: 1.0, // Adjust the threshold as needed
      });
      observer.observe(this.$refs[section][0]);
    });

    window.addEventListener('wheel', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('wheel', this.handleScroll);
  },
};
</script>
