<template>
    <section ref="hero" id="hero" :class="[
        'hero-section text-center',
        isBackgroundPrimary ? 'bg-gradient-primary text-white' : '',
        'py-5 min-vh-100 rounded'
    ]" :style="{ backgroundImage: `url(${backgroundImage})` }">
      <div class="container d-flex flex-column justify-content-center h-100">
        <h1 class="text-white"><b>{{ title }}</b></h1>
        <h2 class="text-white">{{ subtitle }}</h2>
        <div class="button-container text-center mt-3">
          <b-button variant="primary" @click="$emit('scrollToSection', 'form')" pill>
            <span class="text-light">{{ buttonText }}</span>
          </b-button>
          <b-button variant="light" @click="$emit('scrollToSection', 'functionalities')" pill>
            <span class="text-primary">{{ exploreText }}</span>
          </b-button>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { BButton } from 'bootstrap-vue';
  
  export default {
    name: 'HeroSection',
    components: {
      BButton
    },
    props: {
      isBackgroundPrimary: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: 'AquaDentist'
      },
      subtitle: {
        type: String,
        default: 'Pour une organisation optimisée de votre cabinet.'
      },
      exploreText: {
        type: String,
        default: 'Explorer'
      },
      buttonText: {
        type: String,
        default: 'Nous Rejoindre'
      },
      mainRoute: {
        type: String,
        default: 'aqua-main'
      },
      backgroundImage: {
        type: String,
        default: '@/assets/images/banner/aquaMedico-banner.jpg'
      }
    }
  }
  </script>
  
  <style scoped>
  .min-vh-100 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .hero-section {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .hero-section h1 {
    font-size: 6rem;
    margin-bottom: 3rem;
  }
  
  @media (max-width: 768px) {
    .hero-section h1 {
      font-size: 5rem;
      margin-bottom: 3rem;
    }
  }
  
  @media (max-width: 576px) {
    .hero-section h1 {
      font-size: 3.5rem;
      margin-bottom: 2.5rem;
    }
  }
  
  @media (max-width: 375px) {
    .hero-section h1 {
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
  </style>
  