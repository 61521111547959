<template>
    <section id="form" ref="form" :class="[
        'form-section',
        isBackgroundPrimary ? 'my-bg-primary text-white' : '',
        'pt-1 min-vh-100 rounded'
    ]">
        <div class="container d-flex flex-column justify-content-center h-100 pt-3">
            <h1 :class="[
                'text-center',
                isBackgroundPrimary ? 'text-white' : 'text-primary',
            ]">
                Joindre Aqua<span class="text-black">{{ capitalize(form.specialty) }}</span>
            </h1>

            <b-row>
                <b-col sm="1" md="3"></b-col>
                <b-col sm="10" md="6" class="mt-1 pt-3 pb-2 px-5 bg-primary rounded-lg">
                    <h2 class="text-center text-white">Demande un compte</h2>
                    <p class="text-center text-muted">
                        Nous vous contacterons dans les plus brefs délais.
                    </p>

                    <!-- New Form Section -->
                    <b-form @submit.prevent="handleSubmit" class="mt-2" novalidate>
                        <b-row>

                            <!-- Specialties -->
                            <b-col sm="1" md="3"></b-col>
                            <b-col sm="10" md="6">
                                <b-form-group 
                                    label-class='text-white font-weight-bold text-center' 
                                    label="Spécialité*"
                                    label-for="specialty-dropdown"
                                    :state="isFieldValid('specialty')"
                                    invalid-feedback="Veuillez sélectionner une spécialité.">
                                    <b-form-select 
                                        id="specialty-dropdown"
                                        v-model="form.specialty"
                                        :options="specialties"
                                        :disabled="!!specialty"
                                        class="text-primary text-center font-weight-bold" />
                                </b-form-group>
                            </b-col>

                            <!-- Nom Complet -->
                            <b-col cols="12">
                                <b-form-group 
                                    label-class='text-white font-weight-bold' 
                                    label="Nom Complet*"
                                    label-for="vi-full-name"
                                    :state="isFieldValid('fullName')"
                                    invalid-feedback="Le nom complet est obligatoire et doit comporter moins de 50 caractères.">
                                    <b-input-group class="input-group-merge">
                                        <b-input-group-prepend is-text>
                                            <feather-icon class="text-primary" icon="UserIcon" />
                                        </b-input-group-prepend>
                                        <b-form-input 
                                            id="vi-full-name" 
                                            v-model="form.fullName"
                                            placeholder="Nom Complet" 
                                            class="pl-1 text-dark"
                                            @blur="validateField('fullName'); touchedFields.fullName = true" />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <!-- Phone -->
                            <b-col cols="12">
                                <b-form-group 
                                    label-class='text-white font-weight-bold' 
                                    label="Numéro de téléphone*"
                                    label-for="vi-phone"
                                    :state="isFieldValid('phone')"
                                    invalid-feedback="Veuillez entrer un numéro de téléphone valide.">
                                    <b-input-group class="input-group-merge">
                                        <b-input-group-prepend>
                                            <b-dropdown 
                                                class="wrap-dropdown" 
                                                v-model="form.dialCode" 
                                                variant="light"
                                                scrollable>
                                                <template #button-content>
                                                    <span v-if="form.dialCode" class="text-primary">{{ form.dialCode }}</span>
                                                    <feather-icon v-else class="text-primary" icon="SmartphoneIcon" />
                                                </template>
                                                <b-dropdown-item 
                                                    v-for="code in codesBeforeDivider" 
                                                    :key="code.name"
                                                    @click="form.dialCode = code.dial_code">
                                                    {{ code.name }} ({{ code.dial_code }})
                                                </b-dropdown-item>
                                                <div class="dropdown-divider" />
                                                <b-dropdown-item 
                                                    v-for="code in codesAfterDivider" 
                                                    :key="code.name"
                                                    @click="form.dialCode = code.dial_code">
                                                    {{ code.name }} ({{ code.dial_code }})
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-input-group-prepend>
                                        <b-form-input 
                                            id="vi-phone" 
                                            v-model="form.phone" 
                                            type="number"
                                            placeholder="Phone" 
                                            class="pl-1 text-dark"
                                            @blur="validateField('phone'); touchedFields.phone = true" />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <!-- Email -->
                            <b-col cols="12">
                                <b-form-group 
                                    label-class='text-white font-weight-bold' 
                                    label="Email*"
                                    label-for="vi-email"
                                    :state="isFieldValid('email')"
                                    invalid-feedback="Veuillez entrer une adresse email valide.">
                                    <b-input-group class="input-group-merge">
                                        <b-input-group-prepend is-text>
                                            <feather-icon class="text-primary" icon="MailIcon" />
                                        </b-input-group-prepend>
                                        <b-form-input 
                                            id="vi-email" 
                                            v-model="form.email" 
                                            type="email"
                                            placeholder="Email" 
                                            class="pl-1 text-dark"
                                            @blur="validateField('email'); touchedFields.email = true" />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <!-- Ville -->
                            <b-col cols="12">
                                <b-form-group 
                                    label-class='text-white font-weight-bold' 
                                    label="Ville*"
                                    label-for="vi-city"
                                    :state="isFieldValid('city')"
                                    invalid-feedback="La ville ne doit pas dépasser 50 caractères.">
                                    <b-input-group class="input-group-merge">
                                        <b-input-group-prepend is-text>
                                            <feather-icon class="text-primary" icon="MapPinIcon" />
                                        </b-input-group-prepend>
                                        <b-form-input 
                                            id="vi-city" 
                                            v-model="form.city" 
                                            placeholder="Ville"
                                            class="pl-1 text-dark"
                                            @blur="validateField('city'); touchedFields.city = true" />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" class="text-center text-white">
                                <small>En cliquant sur "Envoyer" j'accepte les
                                    <a href="#" class="text-info">CGU et CGV</a> de aqua-medico.com
                                </small>
                            </b-col>

                            <!-- Reset and Submit Buttons -->
                            <b-col cols="12" class="text-center">
                                <b-button 
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
                                    type="submit"
                                    variant="gradient-success" 
                                    class="mr-1 mt-2 px-5">
                                    Envoyer
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-col>
            </b-row>
        </div>
    </section>
</template>

<script>
import {
    BRow, BCol, BFormGroup, BFormInput, BFormSelect, BForm, BButton, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import countryCodes from '../../countryCodes';
import { retrieveColumnLayout } from 'echarts/lib/layout/barGrid';

export default {
    name: 'FormSection',
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BInputGroup,
        BInputGroupPrepend,
        BForm,
        BButton,
        BDropdown,
        BDropdownItem,
    },
    props: {
        isBackgroundPrimary: {
            type: Boolean,
            default: false
        },
        specialty: {
            type: String,
            default: null
        }
    },
    directives: {
        Ripple,
    },
    data() {
        const specialties = [
            { text: 'Choisir une spécialité', value: 'medico', disabled: true },
            { text: 'Dentiste', value: 'dentist' },
            { text: 'Cardiologue', value: 'cardio' },
            { text: 'Dermatologue', value: 'dermato' },
        ];
        const importantCountries = ["MA", "FR", "DE", "US", "GB"]

        const codesBeforeDivider = countryCodes.filter(c => {
            return importantCountries.includes(c.code)
        })
        const codesAfterDivider = countryCodes.filter(c => {
            return !importantCountries.includes(c.code)
        })
        return {
            form: {
                specialty: this.specialty || 'medico',
                fullName: '',
                email: '',
                dialCode: '+212',
                phone: '',
                city: '',
            },
            formErrors: {
                fullName: '',
                phone: '',
                email: '',
                city: '',
                specialty: '',
            },
            touchedFields: {
                fullName: false,
                phone: false,
                email: false,
                city: false,
                specialty: false,
            },
            codesBeforeDivider,
            codesAfterDivider,
            // countryCodes,
            specialties
        };
    },
    computed: {
        specialtyName() {
            const selectedSpecialty = this.specialties.find(spec => spec.value === this.form.specialty);
            return selectedSpecialty ? selectedSpecialty.text : 'Medico';
        }
    },
    methods: {
        capitalize(s) {
            return s.charAt(0).toUpperCase() + s.slice(1);
        },
        validateField(field) {
            if (field === 'fullName') {
                this.formErrors.fullName = 
                    this.form.fullName.length === 0
                        ? 'Le nom complet est requis.'
                        : this.form.fullName.length > 50
                            ? 'Le nom complet ne doit pas dépasser 50 caractères.'
                            : '';
            } else if (field === 'phone') {
                const phoneRegex = /^[0-9]{8,15}$/; 
                this.formErrors.phone = 
                    !phoneRegex.test(this.form.phone)
                        ? 'Veuillez entrer un numéro de téléphone valide.'
                        : '';
            } else if (field === 'email') {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                this.formErrors.email = 
                    !emailRegex.test(this.form.email)
                        ? 'Veuillez entrer une adresse email valide.'
                        : '';
            } else if (field === 'city') {
                this.formErrors.city = 
                    this.form.city.length === 0
                        ? 'La ville est requise.'
                    : this.form.city.length > 50
                        ? 'La ville ne doit pas dépasser 50 caractères.'
                        : '';
            } else if (field === 'specialty') {
                this.formErrors.specialty = 
                    this.form.specialty === 'medico'
                        ? 'Veuillez sélectionner une spécialité.'
                        : '';
            }
        },
        isFieldValid(field) {
            if (!this.touchedFields[field]) return true; // Skip validation if not touched
            this.validateField(field);
            return !this.formErrors[field];
        },
        resetForm() {
            this.form = {
                specialty: this.specialty || 'medico',
                fullName: '',
                email: '',
                dialCode: '+212',
                phone: '',
                city: '',
            };
            this.touchedFields = {
                fullName: false,
                phone: false,
                email: false,
                city: false,
                specialty: false,
            };   
        },
        handleSubmit() {
            // Validate all fields before submission
            this.validateField('fullName');
            this.validateField('phone');
            this.validateField('email');
            this.validateField('city');
            this.validateField('specialty');

            // Check if there are any errors
            const hasErrors = Object.values(this.formErrors).some(error => error);
            if (hasErrors) {
                this.$swal({
                    icon: 'error',
                    title: 'Erreur',
                    text: 'Veuillez corriger les erreurs dans le formulaire.',
                    customClass: {
                        confirmButton: 'btn btn-danger',
                    },
                });
                return;
            }
            // Submit form data
            this.$http.post('/accountRequests', this.form)
                .then(() => {
                    console.log('Account Requested Successfully', this.form);
                    this.$swal({
                        icon: 'success',
                        title: 'Succès',
                        text: 'Votre demande a été envoyée avec succès.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    }).then(() => {
                        this.resetForm();
                    });
                })
                .catch(error => {
                    console.log('Submission error:', error);
                    this.$swal({
                        icon: 'error',
                        title: 'Erreur',
                        text: 'Une erreur s\'est produite lors de l\'envoi de votre demande. Veuillez réessayer.',
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                    });
                });
        },
    },
};
</script>

<style scoped>

.my-bg-primary {
    background-color: #347BD2; 
}
.min-vh-100 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Responsive font size */
.form-section h1 {
    font-size: 2.5rem;
    /* Adjust as needed */
}

@media (max-width: 768px) {
    .form-section h1 {
        font-size: 2rem;
    }
}

@media (max-width: 576px) {
    .form-section h1 {
        font-size: 1.5rem;
    }
}

.wrap-dropdown /deep/ .dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
}
</style>